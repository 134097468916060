.vid-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.vid-container h2{
    font-size: 25pt;
    margin-top: 20px;
}

.vid-container p{
    font-family: lato;
    font-weight: 300;
    font-size: 14pt;
    margin: 20px 75px;
}

.vid-container video{
    margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
    .vid-container h2 {
        font-size: 20pt;
        margin-top: 10px;
    }

    .vid-container p {
        font-size: 12pt;
        margin: 10px 20px;
    }

    .vid-container video {
        width: 95%;
    }
}