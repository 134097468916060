.title{
    background-color:#f8f6ee;
    display: flex;
    height: 600px;
}

.title-column {
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
}

.title-column-text {
    padding-top: 175px;
}

.title-column h1 {
    text-align: center;
    font-family: "Alice";
    font-size: 44px;
}

#heart-img {
    margin: 20px auto;
    width: 100px;
}

#triangle-img{
    margin-top: 100px;
    height:400px;
    width:600px;
    border-radius: 50px;
}

.sign-up{
    background-color: white;
    margin: 100px 0px;
    text-align: center;
}

.sign-up h1{
    font-family: "Alice";
    font-size: 36px;
}

.divider{
    border-bottom: 1px solid black;
    height: 40px;
    width: 125px;
    margin-left: 45%;
}

.sign-up p{
    padding: 20px 400px;
    margin-bottom: 25px;
    font-family: "Lato";
    font-size: 20px;
}

.signUp-btn{
    background-color: #8c6d6c;
    padding: 15px 75px;
    color: white;
    font-size: 20px;
}

.links-section-1{
    background-color:#f8f6ee;
    height:130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
}

.stay-connected{
    background-color: #fef2ec;
    border-radius: 30px;
    border: 1px solid black;
    padding: 10px 0;
    width: 75%;
    margin-top: 50px;
    font-family: "Alice";
    font-size: 36px;
    text-align: center;
    
}

#connect{
    opacity: 0;
    transition: opacity 3s;
}

#connect.fade-in{
    opacity: 1;
}

.links-section-2{
    background-color:#f8f6ee;
    height: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 400px;
    font-family: "Lato";
    font-weight: bold;
    font-size: 18px;
    text-align: center;
}

.instagram a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.instagram img{
    width: 65%;
    margin: 0;
}

.instagram a:hover{
    color: #985252;
}

.discord a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.discord img{
    width: 75%;
    margin: 0;
} 

.discord a:hover{
    color: #985252;
}

.linkedin a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.linkedin img{
    width: 65%;
    margin: 0;
}

.linkedin a:hover{
    color: #985252;
}

/* Responsive design */
@media only screen and (max-width: 500px){
    .title-column-text{
        padding-top: 50px;
    }

    .title-column-text h1{
        font-size: 8vw;
    }

    #heart-img{
        width: 20%;
    }

    #triangle-img{
        margin: auto auto;
        width: auto;
        height: auto;
    }

    .sign-up h1{
        margin: auto 10px;
        font-size: 8vw;
    }

    .sign-up p{
        font-size: 4vw;
    }

    .signUp-btn{
        padding: 15px 30px;
    }

    .stay-connected{
        width: 75%;
        font-size: 8vw;
    }

    .links-section-2{
        flex-direction: column;
        height: 300px;
    }

    .instagram{
        width: 60px;
        margin-top: 10px;
    }
    
    .discord{
        width: 70px;
    }

    .linkedin {
        width: 65px;
        margin-top: 0px;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 500px) and (max-width: 768px){
    .title-column-text{
        padding-top: 100px;
    }

    #triangle-img{
        margin: auto auto;
        height: 75%;
        width: 75%;
    }
}

@media only screen and (max-width: 768px) {
    .title{
        flex-direction: column;
        height: auto;
        padding-bottom: 20px;
    }

    .divider{
        margin: auto;
    }

    .sign-up p{
        padding: 20px 40px;
    }

    .links-section-2{
        padding: 0px 50px;
    }

} 

@media only screen and (min-width: 768px) and (max-width: 1000px) {
    .title{
        flex-direction: column;
        height: 800px;
    }

    .title-column-text{
        padding-top: 100px;
    }
    
    #triangle-img{
        margin: auto auto;
        height: auto;
        max-width: 75%;
    }

    .sign-up p{
        padding: 20px 60px;
    }

    .links-section-2{
        padding: 25px 20%;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 1200px){
    .sign-up p{
        padding: 20px 100px;
    }

    .links-section-2{
        padding: 0px 30%;
    }
}
