.gallery-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery-section h2{
    font-size: 25pt;
    margin: 20px 0;
}

.gallery-columns{
    width: 100%;
    display: flex;
    justify-content: space-around;

}

.gallery-columns div{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 50px;
}

.gallery-columns img{
    margin: 20px 0;
}




.fullscreen-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 999;
}

.fullscreen-image {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90%;
    object-fit: contain;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

@media screen and (max-width: 1000px){
    .gallery-section h2 {
        font-size: 22pt;
        margin: 15px 0;
    }

    .gallery-columns div {
        width: 45%; /* Adjust this value as needed */
        margin: 0 10px;
    }
    .fullscreen-image{
        
        height: 40%;
        
    }

    
}

@media screen and (max-width: 600px) {
    .gallery-section h2 {
        font-size: 20pt;
        margin: 10px 0;
    }

    .gallery-columns {
        /* flex-direction: column;
        align-items: center; */
        width: 100%;
    }

    .gallery-columns div {
        width: 100%;
        margin: 0 5px;
    }

    .fullscreen-image {
        width: 90%;
        height: auto;
    }
}

