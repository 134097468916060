.access-denied-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
    background-color: #f8f9fa;
    color: #343a40;
    text-align: center;
    padding: 20px;
}

.access-denied-pane h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.access-denied-pane p {
    font-size: 1.2em;
    margin-bottom: 40px;
}

#access-btn {
    padding: 10px 20px;
    font-size: 1.2em;
    color: #fff;
    background-color:#8C6D6C;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

#access-btn:hover {
    background-color: #725c5b;
}