.about-btn{
    text-decoration: none;
    background-color: #8c6d6c;
    width: fit-content;
    padding: 15px 75px;
    color: white;
    text-align: center;
}

.mainContent {
    display: flex;
    flex-direction: column;
    background-color: #f7f2f2;
    padding: 20px;
    height: fit-content;
    align-items: center;
}

.mainContent h1{
    font-size: 44px;
    text-align: center;
    padding-bottom: 7%;   
}

.sideWaysContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8%;
}

.triangletext {
    flex: 1;
    display: flex;
    align-items: center;
    text-align: center;
}

.triangletext h3 {
    font-size: 28px;
    font-style: italic;
}

.button {
    align-items: center;
    padding-bottom: 10px;
}

@media (max-width: 768px) {
    .sideWaysContainer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    .triangletext {
        flex: 1;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }
}