.upcoming-container {
    min-height: 75vh;
    background-size: cover;
    background-position: center;
    color: white;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    padding:0 100px;
   
}

.upcoming-container h1{
    font-size: 40pt;
}

.event{
    display: flex;
    flex-direction: column;
}

.event h2{
    font-size: 30pt;
}

.event p {
    width: 50%;
    font-size: 12pt;
    font-family: lato;
    font-weight: 300;
}

.check-btn{
    background-color: #8C6D6C;
    color:white;
    border-radius: 10px;;
    padding: 20px 80px;
    margin-top: 20px;
    width: fit-content;
    font-family: lato;
    font-weight: 800;
}

.dots{
    display: flex;
    justify-content:center;
    background-color: #FFF2EB;
    padding: 20px;
}

.dot {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

#active-dot{
    background-color: #717171;
}

.sign-up-container{
    background-color: black;
    border-radius: 20px;
    color: white;
    display: flex;
    justify-content: space-between;
    margin: 75px;
    padding: 50px;
}

.sign-up-container h3{
    font-size: 20pt;
}

.sign-up-container-body{
    display: flex;
    flex-direction: column;
    font-family: lato;
    font-size: 14pt;
    width: 50%;
}

.pagination{
    text-align: center;
}

.pagination button{
    margin: 0 10px;
    height: 30px;
    width: 30px;
    background-color: #999;
    border: none;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    font-size:  16px;
    transition: background-color 0.3s ease;
}

#active-page{
    background-color: #8C6D6C;
}

.no-events-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 75vh;
    padding: 20px;
    background-color: #f9f9f9; /* Change to match your site's color scheme */
    border-radius: 5px;
    margin: 20px 0;
}

.no-events-section h2 {
    color: #8C6D6C; /* Change to match your site's color scheme */
    font-size: 24px;
    margin-bottom: 20px;
}

.no-events-section a {
    display: inline-block;
    color: #fff; /* Change to match your site's color scheme */
    background-color: #8C6D6C; /* Change to match your site's color scheme */
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.no-events-section .btn:hover {
    background-color: #666; /* Change to match your site's color scheme */
}

@media screen and (max-width: 768px) {
    .upcoming-container {
        padding: 0 20px;
        min-height: 50vh;
    }

    .upcoming-container h1 {
        font-size: 20pt;
        
    }

    .event h2{
        font-size: 16pt;
    }

    .event h5{
        font-size: 12pt;
    }

    .event h6{
        font-size: 10pt;
    }

    .event p {
        width: 85%;
        font-size: 10pt;
    }

    .check-btn {
        font-size: 10pt;
        padding: 10px 30px;
        margin-top: 20px;
    }

    .sign-up-container {
        flex-direction: column;
        margin: 20px;
        padding: 20px;
        align-items: center;
        text-align: center;
    }

    .sign-up-container h3{
        font-size: 18pt;
        margin-bottom: 20px;
    }

    .sign-up-container-body {
        width: 100%;
    }

    .sign-up-container-body a{
        margin: 20px auto 10px auto;
    }
}

@media screen and (max-width: 1000px) {
    .upcoming-container{
        min-height: 50vh;
    }

    .event p {
        width: 75%;
    }

    .sign-up-container {
        flex-direction: column;
        margin: 50px 25px;
        padding: 20px;
        align-items: center;
        text-align: center;
    }

    .sign-up-container h3{
        font-size: 18pt;
        margin-bottom: 20px;
    }

    .sign-up-container-body {
        width: 100%;
    }

    .sign-up-container-body a{
        padding: 10px 40px;
        margin: 20px auto 10px auto;
    }
}   