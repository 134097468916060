.email-header{
    background-color:#f8f6ee;
    display: flex;
    height:400px;
    padding: 100px;
}

.email-column{
    flex: 1;
    padding: 20px;
    font-size: 210%;
    text-align: center;
}

.email-column:nth-child(2){
    background-color: white;
    padding: 55px 20px;
    padding-bottom: 575px;
}

/* contact form */
.email-form{
    margin: auto;
    font-size: 50%;
    text-align: left;
}

.email-form form{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: auto;
    padding-top: 30px;
}

.email-form label{
    color: #484848;
}

.email-form input{
    color: black;
    width: 100%;
    height: 30px;
    padding: 8px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e8ccc4;
}

.email-form textarea{
    color: black;
    width: 100%;
    max-height: 80px;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #e8ccc4;
}

.email-form button{
    background-color: #8c6d6c;
    color: white;
    width: 40%;
    padding: 10px;
    border: none;
}

.email-form p{
    text-align: left;
    color: #8c6d6c;
}


#contact-logo{
    width: 60px;
    margin: auto auto;
}

.pic-scroll{
    display: flex;
    justify-content: space-around;
    background-image: url("../img/contact-img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    align-items: center;
    padding: 200px 0;
}

.contact-info {
    background-color: white;
    display: flex;
    height: auto;
    padding: 100px 150px;
}

.info-section {
    flex: 1;
    font-family: "Alice";
}

.info-section h1 {
    font-size: 210%;
}

.info-section p {
    padding-top: 25px;
    padding-right: 75px;
    font-size: 120%;
}

.highlight {
    color: #985252;
}

/* Responsive design */
@media only screen and (max-width: 574px){
    .email-column h1{
        font-size: 5vw;
    }
}

@media only screen and (max-width: 780px) {
    .email-header{
        flex-direction: column;
        height:600px;
        padding: 50px;
    }
    
    .email-column {
        padding: 30px 0px;
        text-align: center;
    }

    .email-column:nth-child(2){
        background-color: white;
        padding: 55px 50px;
        padding-bottom: 100px;
    }
    
    .email-form button {
        width: 100%;
    }
} 

@media only screen and (max-width: 1010px){
    .contact-info {
        flex-direction: column;
    }

    .maps {
        padding: 50px 0px;
    }

    .maps iframe {
        height: auto;
        width: 80vw;
    }
}

@media only screen and (max-width: 1200px){
    .contact-info {
        padding: 30px 50px;
    }
}