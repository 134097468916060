.event-backdrop{
    border-bottom: 5px solid #8C6D6C;
    border-top: none;
    height: 500px;
    background-position: center;
    background-size: cover;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 75px;
}

.event-backdrop div{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.event-backdrop h1{
    font-size: 40pt;
}

.event-backdrop h4{
    font-size: 12pt;
}

.event-backdrop p{
    font-family: lato;
    font-size: 14pt;
    font-weight: 300;
}

.event-options{
    display: flex;
    justify-content: space-around;
    background-color: #4C5053;
    color: white;
}

.event-options div{
    padding: 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 2px solid black;
    
}

.event-options div:not(.active):hover {
    background-color: #2A292A;
}

.event-options .active{
    background-color: #8C6D6C;
}

.event-options img{
   width: 50px;
   filter: invert(1);
}

.book-btn{
    background-color: #8C6D6C;
    color:white;
    border-radius: 10px;;
    padding: 15px 50px;
    margin-top: 20px;
    width: fit-content;
    font-family: lato;
    font-weight: 800;
    
}

@media screen and (max-width: 1000px) {
    .event-backdrop div{
        width: 75%;
    }
    .event-backdrop p{
        font-size: 12pt;
    }
}

@media screen and (max-width: 768px) {
    .event-backdrop {
        padding: 20px;
        height: auto;
    }

    .event-backdrop div {
        width: 100%;
        padding: 20px 0;
    }

    .event-backdrop h1 {
        font-size: 20pt;
    }

    .event-backdrop p{
        font-size: 11pt;
    }

    .event-options {
        font-size: 10pt;
    }

    .event-options div {
        width: 100%;
        border-right: none;
        border-bottom: 2px solid black;
    }

    .event-options img {
        width: 20px;
        margin: 5px 0;
    }

    .book-btn{
        font-size: 10pt;
        padding: 15px 30px;
    }
}

