body{
    background-color: white;
    font-family: "Alice";
}

.title-head{
    background-color: white;
    margin: none;
    /* height: 200px; */
    padding: 75px;
    text-align: center;
    
}

.title-head h1{
    margin: 0;
    padding: 0;
    font-family: "Alice"; 
    font-size: 50pt;
}

.title-head h4{
    margin: 0;
    padding: 0;
    font-family: "Lato";
    font-size: 20pt;
    font-weight:lighter;
}

#title-img{
    display: block;
    width:100%;
    height: 100%;
    margin: 0;
}

.about-blurb{
    background-color: #8C6D6C;
    text-align: center;
    font-family: "Alice";
    padding: 100px 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-blurb h2{
    font-size: 40pt;
    margin: 0;
    margin-bottom: 50px;
}

.about-button{
    background-color: black;
    width: fit-content;
    padding: 15px 75px;
    color: white;
}

.statement-img-img{
    background-color: #F6F6F6;
    padding: 100px;
    font-family: "Alice";
    text-align: center;
}

.statement-img-img h1{
    font-size: 40pt;
    margin-bottom: 50px;
}

.img-container{
    display: flex;
    justify-content: space-between;
    /* overflow: hidden; */
}

.img-wrapper{
    width: 49.5%;
    overflow: hidden;
}

.img-wrapper img{
    width: 100%;
    height: 100%;
    margin: 0;
    transition: transform 1s ease-in-out;
}

.img-container img:hover{
    transform: scale(1.1);
}

.why-join-section{
    padding: 50px 50px;
    text-align: center;
    font-family: "Alice";
    font-size: 30pt;
}

.text-container{
    margin-left: 50px;
    display: flex;
    text-align: left;
}

.text-container div{
    padding-top: 50px;
    margin: 0 15px;
}

.text-container h6{
    font-size: 16pt;
}

.text-container p{
    font-family: "Lato";
    font-size: 12pt;
    font-weight: 300;
}

#blurb{
    opacity: 0;
    transition: opacity 3s;
}

#blurb.fade-in{
    opacity: 1;
}


/* Responsive styles */
@media screen and (max-width: 768px) {
    .title-head {
        padding: 50px;
    }

    .title-head h1 {
        font-size: 30pt;
    }

    .title-head h4 {
        font-size: 16pt;
    }

    .about-blurb {
        padding: 40px 25px;
    }
    .about-blurb h2 {
        font-size: 16pt;
        margin-bottom: 20px;
    }

    .about-btn {
        padding: 10px 40px;
        font-size: 10pt;
    }

    .statement-img-img {
        padding: 40px 25px;
    }

    .statement-img-img h1 {
        font-size: 20pt;
        margin-bottom: 25px;
    }

    .why-join-section {
        font-size: 20pt;
        padding: 40px 25px;
    }

    .text-container {
        margin: 0;
        flex-direction: column;
    }

    .text-container div {
        padding-top: 30px;
    }

    .text-container h6 {
        font-size: 14pt;
    }
    .text-container p {
        font-size: 10pt;
    }
}