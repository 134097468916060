nav {
    position: sticky;
    top:0;
    z-index: 200;
    display: flex;
    justify-content:space-between;
    background-color: #FFF2EB;
    margin: 0;
    padding: 20px 100px;
    width:auto;
    transition: background-color 0.5s, padding 0.5s;
}

nav.scrolled{
    background-color: white;
    padding: 15px 100px;
}

#nav-logo {
    height: 50px;
    /* margin-left: 100px; */
}

.links, .resources{
    display: flex;
    margin: 0 10px;
}

.link, .resources{
    margin: auto 30px;
    text-decoration: none;
    color: black;
}

.active{
    font-weight: bold;
}

/* button{
    background-color: black;
    padding: 10px 75px;
    margin: 0;
    border: none;
} */

.login{
    display: flex;
    text-decoration: none;
    background-color: black;
    padding: 10px 75px;
    margin: 5px 0;
    color: white;
}

.login.active{
    font-weight: normal;
}


.links, .resources, .login {
    display: flex;
}

.menu-icon {
    display: none;
}

/*Resources dropdown menu*/
.resources {
    position: relative;
    display: inline-block;
}
  
.dropdown-content {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 15px 5px;
    text-decoration: none;
    display: block;
}

.dropdown-content:hover{
    background-color: #f1f1f1;
}

.resources:hover, .dropdown-content {
    display: block;
}

.arrow-up {
    transform: rotate(180deg);
    transition: transform 0.3 ease;
    display: inline-block;
}

/* .arrow-down {
    display: inline-block;
} */

@media (max-width: 1000px) {
    nav{
        flex-direction: column;
        padding: 20px 50px;
    }

    .mobile-menu{
        display: flex;
        justify-content: space-between;
    }

    .links, .login {
        display: none;
    }

    .menu-icon {
        display: block;
        font-size: 20pt;
        color: black;
    }

    .show {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        font-size: 14pt;
    }

    .show a {
        margin: 2px 0;
        border-bottom: 0.5px solid grey;
    }

    .login{
        width: fit-content;
        align-items: center;
        margin: 0 auto;
    }
}