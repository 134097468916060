.upcoming-pane {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75vh;
    background-color: #f4f4f4;
    color: #333;
    font-family: 'Lato', sans-serif;
}

.upcoming-pane h2 {
    font-size: 2.5em;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.upcoming-pane h4 {
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;
    color: #666;
}

@media screen and (max-width: 768px) {
    .upcoming-pane {
        height: 50vh;
        padding: 20px;
    }

    .upcoming-pane h2 {
        font-size: 1.5em;
    }

    .upcoming-pane h4 {
        font-size: 1em;
    }
}