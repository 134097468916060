.pdf-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-section h2{
    font-size: 25pt;
    margin-top: 20px;
}

.pdf-section p{
    font-family: lato;
    font-size: 14pt;
    font-weight: 300;
    margin: 20px 75px;
}

.pdf-section iframe{
    width: 90%;
    height: 90vh;
    margin: 20px 0;
}

#signout-btn{
    font-size: 10pt;
    padding: 5px 10px;
    margin: 0;
    margin-bottom: 40px;
}

@media screen and (max-width: 768px) {
    .pdf-section h2 {
        font-size: 20pt;
        margin-top: 10px;
    }

    .pdf-section p {
        font-size: 12pt;
        margin: 10px 20px;
    }

    .pdf-section iframe {
        width: 95%;
        height: 50vh;
    }
}