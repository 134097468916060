/* tab and menu styling */
.amplify-tabs__item{
    color: #8c6d6c;
}

.amplify-tabs__item:hover{
    color: white;
    border-color: #985252;
    background-color:#8c6d6c
}

.amplify-tabs__item--active{
    border-color: #985252;
}

.amplify-label{
    color: #8c6d6c;
    font-weight: bold;
}

/* button styling */ 
.amplify-button{
    color:#8c6d6c;
    border-color: #8c6d6c;
}

.amplify-button:hover{
    background-color: #8c6d6c;
    color: white;
}

.amplify-button:active{
    background-color: #8c6d6c;
}