.body-header{
    position: relative;
    background-image: url("../img/3.jpg");
    background-position: 25% 40%;
    background-repeat: no-repeat;
    background-size:cover;
    padding: 125px 0;
    font-family: "Alice";
    text-align: center;
    
}

.body-header::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
}

.body-header h1{
    margin: 0;
    position: relative;
    color: white;
    font-size: 40pt;
}

.about-section{
    padding: 100px 75px;
    text-align: center;
}

.about-section h3{
    font-size: 36pt;
    font-family: "Alice";
}

.about-section p{
    font-size: 25pt;
    font-family: "Lato";
    font-weight: 300;
}

.vision-section{
    background-color: red;
    display: flex;
    padding: 50px 150px;
    font-family: "Alice";
    align-items: center;
    visibility: hidden;
}

.vision-section h2{
    border-right: 2px solid black;
    font-size: 50pt;
    text-align: right;
    padding: 20px 40px;
    margin-right: 40px;
}

.vision-section p{
    font-size: 20pt;
    font-family: Lato;
    font-weight: 300;
}

.contact-section{
    padding: 40px 20px;
    text-align: center;
}

.scroll-img-container{
    display: flex;
    justify-content: space-around;
    background-image: url("../img/4.jpg");
    background-size:cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    align-items: center;
    padding: 100px 0;
    
}

.txt-container{
    background-color: white;
    width: 30%;
    padding: 50px;
}

.txt-container h4{
    font-family: "Alice";
    font-size: 28pt;
    margin: 0;
}

.txt-container p{
    font-size: 12pt;
    font-family: Lato;
    font-weight: 300;
    margin: 20px 0 40px;
}

.txt-container a{
    text-decoration: none;
    color: white;
    padding: 15px 50px;
    background-color: #8C6D6C;
}

@keyframes slide-in {
    0%{
        transform: translateX(200px);
        opacity: 0;
    }
    100%{
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in{
    visibility: visible;
    animation: slide-in 2s forwards;
}


@media (max-width: 1000px){
    .vision-section{
        padding: 50px 20px;
    }

    .txt-container{
        width: 50%;
    }

    
}

@media (max-width: 768px) {
    .body-header {
        padding: 60px 0;
    }

    .body-header h1 {
        font-size: 24pt;
    }

    .about-section {
        padding: 50px 30px;
    }

    .about-section h3 {
        font-size: 20pt;
    }

    .about-section p {
        font-size: 14pt;
    }

    .vision-section {
        flex-direction: column;
        padding: 50px 30px;
    }

    .vision-section h2 {
        font-size: 20pt;
        width: 100%;
        text-align: left;
        border-right: none;
        padding:0;
        margin: 0 0 15px 0;
    }

    .vision-section p {
        font-size: 14pt;
        
    }

    .contact-section {
        padding: 20px 10px;
    }

    .scroll-img-container {
        flex-direction: column;
        padding: 40px;
    }

    .txt-container {
        width: 100%;
        padding: 30px 20px;
    }

    .txt-container h4 {
        font-size: 20pt;
    }

    .txt-container p {
        font-size: 12pt;
        margin: 20px 0;
    }

    .txt-container a {
        padding: 10px 25px;
    }
}