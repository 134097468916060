.footer{
    background-color: #FFF2EB;
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 10px;
    padding: 75px 50px;
    align-items:flex-start;
}

.links-column{
    display: flex;
    flex-direction: column;
}

.links-column a{
    text-decoration: none;
    color: black;
    font-family: "Lato";
    margin-bottom: 10px;
    font-size: 11pt;
}

.footer p{
    font-family: lato;
    font-weight: 300;
    font-size: 12pt;
}

#footer-logo{
    margin: 0px;
    height: 75px;
    /* width: 90px; */
}

#contact-btn{
    background-color: black;
    color: white;
    font-size: 10pt;
    padding: 10px 60px;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    #footer-logo{
       height: 60px;
       margin: 10px 0;
    }

    #contact-btn {
        text-align: center; 
    }

    .links-column{
        font-size: 11pt;
    }

    .footer p{
        font-size: 12pt;
        margin: 10px 0;
    }
}